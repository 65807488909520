import { MainPage } from "../mainpage"

export const Home = () => {
    return (
        <>
        
            <MainPage />
  
        </>



    )
}