import { Container, Row, Form, Col, Button } from "react-bootstrap"
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
export default function Seller(props) {
    console.log(props);

    localStorage.clear()
    const navigate = useNavigate();
    const [interest, setInterest] = useState("")
    const [isVisible, setIsVisible] = useState(true);
    const [frameState, setframeState] = React.useState(false)
    const [state, setstate] = useState({
        Name: "",
        Company: "",
        Designation: "",
        EmailId: "",
        MeetingDateTime: "",
        PhoneNo: "",
        interest: "0"
    });

    const DataSubmit = async (e) => {



    }
    const handleInterrest = (e) => {

        // setDemo(e.target.value)
        if (e.target.value === "demo") {
            setstate({ ...state, interest: e.target.value })
        }
        else {
            // setstate({ ...state, }/)
            setstate({ ...state, interest: e.target.value == "0" ? "brochure" : e.target.value, MeetingDateTime: "" })
        }
    }
    const DateFormetselect = (e) => {



    }
    const clearData = () => {
        setstate({
            Name: "",
            Company: "",
            Designation: "",
            EmailId: "",
            MeetingDateTime: "",
            PhoneNo: "",
            interest: "0"
        })
    }




    const SignPopup = (url) => {

    }
    return (

        <section className="washing-wale-seller">
         


        </section>
    );
}
