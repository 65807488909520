
import "./index.scss"
export default function CarList() {
    return (
        <section className="car-washing-wale-list">
              
              
                <div class="row" id="">
                    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                        <div class="card explore-feature border-1 rounded text-center bg-white">
                            <a href="/washing-wale-shopping" id="for_business_user_sub" fdi="chauffeur">
                            </a><div class="card-body" fdi="chauffeur"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="chauffeur">
                                <div class="icon rounded-circle shadow-lg d-inline-block">
                                    <img src= "/img/convertible.png" class="mt-16 avatar avatar-md-sm" alt="Car Driver hire" />
                                </div>
                            </a><div class="content mt-3"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="chauffeur">
                            </a><h6 class="mb-0"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="chauffeur"></a>
                            <a href="/washing-wale-shopping" class="title text-dark" id="for_business_user_sub" fdi="chauffeur">Hatchback</a></h6>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                        <div class="card explore-feature border-1 rounded text-center bg-white">
                            <a href="/washing-wale-shopping" id="for_business_user_sub" fdi="truck-driver">
                            </a><div class="card-body" fdi="Truck Driver"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="truck-driver">
                                <div class="icon rounded-circle shadow-lg d-inline-block">
                                    <img src="/img/truck.png" class="mt-16 avatar avatar-md-sm" alt="Truck Driver hire" />
                                </div>
                            </a><div class="content mt-3"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="truck-driver">
                            </a><h6 class="mb-0"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="truck-driver"></a>
                            <a href="/washing-wale-shopping" class="title text-dark" id="for_business_user_sub" fdi="truck-driver">Truck</a></h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                        <div class="card explore-feature border-1 rounded text-center bg-white">
                            <a href="/washing-wale-shopping" id="for_business_user_sub" fdi="bus-driver">
                            </a><div class="card-body" fdi="Bus Driver"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="bus-driver">
                                <div class="icon rounded-circle shadow-lg d-inline-block">
                                    <img src="/img/pickup.png" class="mt-16 avatar avatar-md-sm" alt="Bus Driver hire" />
                                </div>
                            </a><div class="content mt-3"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="bus-driver">
                            </a><h6 class="mb-0"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="bus-driver"></a>
                            <a href="/washing-wale-shopping" class="title text-dark" id="for_business_user_sub" fdi="bus-driver">Pickup</a></h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                        <div class="card explore-feature border-1 rounded text-center bg-white">
                            <a href="/washing-wale-shopping" id="for_business_user_sub" fdi="school-bus">
                            </a><div class="card-body" fdi="School Bus"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="school-bus">
                                <div class="icon rounded-circle shadow-lg d-inline-block">
                                    <img src="/img/sedan.png" class="mt-16 avatar avatar-md-sm" alt="School Bus driver" />
                                </div>
                            </a><div class="content mt-3"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="school-bus">
                            </a><h6 class="mb-0"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="school-bus"></a>
                            <a href="/washing-wale-shopping" class="title text-dark" id="for_business_user_sub" fdi="school-bus">Sedan</a></h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                        <div class="card explore-feature border-1 rounded text-center bg-white">
                            <a href="/washing-wale-shopping" id="for_business_user_sub" fdi="van-driver">
                            </a><div class="card-body" fdi="Van Driver"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="van-driver">
                                <div class="icon rounded-circle shadow-lg d-inline-block">
                                    <img src="/img/suv.png" class="mt-16 avatar avatar-md-sm" alt="Van Driver" />
                                </div>
                            </a><div class="content mt-3"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="van-driver">
                            </a><h6 class="mb-0"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="van-driver"></a>
                            <a href="/washing-wale-shopping" class="title text-dark" id="for_business_user_sub" fdi="van-driver">Suv</a></h6>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-6 mt-4 pt-2">
                        <div class="card explore-feature border-1 rounded text-center bg-white">
                            <a href="/washing-wale-shopping" id="for_business_user_sub" fdi="valet">
                            </a><div class="card-body" fdi="Valet"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="valet">
                                <div class="icon rounded-circle shadow-lg d-inline-block">
                                    <img src="/img/bus.png" class="mt-16 avatar avatar-md-sm" alt="Valet Parking" />
                                </div>
                            </a><div class="content mt-3"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="valet">
                            </a><h6 class="mb-0"><a href="/washing-wale-shopping" id="for_business_user_sub" fdi="valet">
                                </a><a href="/washing-wale-shopping" class="title text-dark" id="for_business_user_sub" fdi="valet">Bus</a></h6>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        
        </section>
    )
}