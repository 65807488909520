import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./index.scss"
import React from 'react';
import { Login } from '../login';
export const Header = () => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <div className="car-washing-wale-navbar sticky">
                
                <div className='topheader'>
                    <div className="top-bar">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8 col-sm-4 col-12  top-none">
                                <div className="top-bar-left d-flex">
                                    <div className="clearfix">
                                        <ul className="socials">
                                            <li>
                                                <a className="social-icon text-dark" href="javascript:void(0);"><i className="fa fa-facebook"></i></a>
                                            </li>
                                            <li>
                                                <a className="social-icon text-dark" href="javascript:void(0);"><i className="fa fa-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a className="social-icon text-dark" href="javascript:void(0);"><i className="fa fa-linkedin"></i></a>
                                            </li>
                                            <li>
                                                <a className="social-icon text-dark" href="javascript:void(0);"><i className="fa fa-google-plus"></i></a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-8 col-12">
                                <div className="top-bar-right">
                                    <ul className="custom">
                                        <li>
                                            <a href="/washing-wale-seller" className="text-dark"><i className="fa fa-car me-1"></i> <span>Become a Seller</span></a>
                                        </li>
                                        <li>
                                            <a href="register.html" className="text-dark"><i className="fa fa-user me-1"></i> <span>Register</span></a>
                                        </li>
                                        <li>
                                            <a href="#" onClick={() => setModalShow(true)} className="text-dark"><i className="fa fa-sign-in me-1"></i> <span>Login</span></a>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                <div className="main-menu">
                    <div className="top-menu">
                        <nav>
                            <div className="wrapper">
                                <div className="logo"><a href="/"><img src='/washingwale.png' alt='logo'/></a></div>
                                <input type="radio" name="slider" id="menu-btn" />
                                <input type="radio" name="slider" id="close-btn" />
                                <ul className="nav-links">
                                    <label for="close-btn" className="btn close-btn"><i className="fa fa-times"></i></label>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="#">About</a></li>


                                    <li>
                                        <a href="#" className="desktop-item">Dropdown Menu</a>
                                        <input type="checkbox" id="showDrop" />
                                        <label for="showDrop" className="mobile-item">Dropdown Menu</label>
                                        <ul className="drop-menu">
                                            <li><a href="#">Drop menu 1</a></li>
                                            <li><a href="#">Drop menu 2</a></li>
                                            <li><a href="#">Drop menu 3</a></li>
                                            <li><a href="#">Drop menu 4</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#" className="desktop-item">Mega Menu</a>
                                        <input type="checkbox" id="showMega" />
                                        <label for="showMega" className="mobile-item">Mega Menu</label>
                                        <div className="mega-box">
                                            <div className="content">
                                                <div className="row">
                                                    <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                                                </div>
                                                <div className="row">
                                                    <header>Design Services</header>
                                                    <ul className="mega-links">
                                                        <li><a href="#">Graphics</a></li>
                                                        <li><a href="#">Vectors</a></li>
                                                        <li><a href="#">Business cards</a></li>
                                                        <li><a href="#">Custom logo</a></li>
                                                    </ul>
                                                </div>
                                                <div className="row">
                                                    <header>Email Services</header>
                                                    <ul className="mega-links">
                                                        <li><a href="#">Personal Email</a></li>
                                                        <li><a href="#">Business Email</a></li>
                                                        <li><a href="#">Mobile Email</a></li>
                                                        <li><a href="#">Web Marketing</a></li>
                                                    </ul>
                                                </div>
                                                <div className="row">
                                                    <header>Security services</header>
                                                    <ul className="mega-links">
                                                        <li><a href="#">Site Seal</a></li>
                                                        <li><a href="#">VPS Hosting</a></li>
                                                        <li><a href="#">Privacy Seal</a></li>
                                                        <li><a href="#">Website design</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li><a href="#">Feedback</a></li>
                                </ul>
                                <label for="menu-btn" className="btn menu-btn"><i className="fa fa-bars"></i></label>
                            </div>
                        </nav>


                    </div>
                </div>
               
            </div>
            <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='login-model'
                >

                    <Modal.Body>
                        <Login />
                    </Modal.Body>

                </Modal>
        </>
    )
}