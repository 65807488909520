import './index.scss';
export const Login = () => {
    return (

        <div className="car-washing-login">
            <section class="sptb">
                <div class="container customerpage">
                    <div class="row">
                        <div class="single-page">
                                <div class="wrapper wrapper2">
                                    <form id="login" class="card-body" tabindex="500">
                                        <h3>Login</h3>
                                        <div class="mail"><input type="email" name="mail" /> <label>Mail or Username</label></div>
                                        <div class="passwd"><input type="password" name="password" /> <label>Password</label></div>
                                        <div class="submit"><a class="btn btn-primary btn-block" href="index.html">Login</a></div>
                                        <p class="mb-2"><a href="forgot.html">Forgot Password</a></p>
                                        <p class="text-dark mb-0">Don't have account?<a href="register.html" class="text-primary ms-1 d-inline-block">Sign UP</a></p>
                                    </form>
                                  
                                    <div class="card-body social-images">
                                        <p class="text-body text-left">Sign In to Social Accounts</p>
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <a href="https://www.facebook.com/" class="btn btn-white me-2 border px-2 btn-lg btn-block text-left">
                                                    <img src="../assets/images/svgs/svg/facebook.svg" class="h-5 w-5" alt="" /><span class="ms-3 d-inline-block font-weight-bold"> Facebook</span>
                                                </a>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <a href="https://www.google.com/gmail/" class="btn btn-white me-2 px-2 border btn-lg btn-block text-left">
                                                    <img src="../assets/images/svgs/svg/search.svg" class="h-5 w-5" alt="" /><span class="ms-3 d-inline-block font-weight-bold"> Google</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}