

import React from 'react';
import { SarchBox } from '../searchBox';
import { Listing } from '../listing';
import CarList from '../carlist';
import Discount from '../discount';
// import HowItWorks from '../howItWorks';
// import About from '../about';
import "./index.scss"

export const MainPage = () => {
  return (
    <>
      <SarchBox />
      <CarList />
      <div>
        <div class="washing-section-title1">
          <span>
            <span>Available</span>
            <h2>Most Searched Center</h2></span>
          <sapn><a>View All</a></sapn>
        </div>
        <Listing />
      </div>

      <Discount />
      <div>
      <div class="washing-section-title1">
          <span>
            <span>Available</span>
            <h2>Most Rating Center</h2></span>
          <sapn><a>View All</a></sapn>
        </div>
        <Listing />
      </div>
    </>
  )
}
