import { Container } from "react-bootstrap"
import "./index.scss"
export const Footer = () => {
    return (
        <>
            <div className="car-washing-footer" style={{ backgroundImage: 'url("img/inner-page/product-wd-bg.png")' }}>
                <Container fluid>
                    <footer className="footer-section">


                        <div className="footer-content pt-5 pb-5">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-30">
                                    <div className="footer-widget">

                                        <ul>
                                            <li><a href="#">Home</a></li>
                                            <li><a href="#">about</a></li>
                                            <li><a href="#">services</a></li>
                                            <li><a href="#">portfolio</a></li>
                                            <li><a href="#">Contact</a></li>
                                            <li><a href="#">About us</a></li>
                                            <li><a href="#">Our Services</a></li>
                                            <li><a href="#">Expert Team</a></li>
                                            <li><a href="#">Contact us</a></li>
                                            <li><a href="#">Latest News</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-30">
                                    <div className="footer-widget">

                                        <ul>
                                            <li><a href="#">Home</a></li>
                                            <li><a href="#">about</a></li>
                                            <li><a href="#">services</a></li>
                                            <li><a href="#">portfolio</a></li>
                                            <li><a href="#">Contact</a></li>
                                            <li><a href="#">About us</a></li>
                                            <li><a href="#">Our Services</a></li>
                                            <li><a href="#">Expert Team</a></li>
                                            <li><a href="#">Contact us</a></li>
                                            <li><a href="#">Latest News</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-50">
                                    <div className="footer-widget">

                                        <ul>
                                            <li><a href="#">Home</a></li>
                                            <li><a href="#">about</a></li>
                                            <li><a href="#">services</a></li>
                                            <li><a href="#">portfolio</a></li>
                                            <li><a href="#">Contact</a></li>
                                            <li><a href="#">About us</a></li>
                                            <li><a href="#">Our Services</a></li>
                                            <li><a href="#">Expert Team</a></li>
                                            <li><a href="#">Contact us</a></li>
                                            <li><a href="#">Latest News</a></li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mb-50">
                                    <div className="footer-widget">

                                        <ul>
                                            <li><a href="#">Home</a></li>
                                            <li><a href="#">about</a></li>
                                            <li><a href="#">services</a></li>
                                            <li><a href="#">portfolio</a></li>
                                            <li><a href="#">Contact</a></li>
                                            <li><a href="#">About us</a></li>
                                            <li><a href="#">Our Services</a></li>
                                            <li><a href="#">Expert Team</a></li>
                                            <li><a href="#">Contact us</a></li>
                                            <li><a href="#">Latest News</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className="copyright-area">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                    <div className="copyright-text">
                                        <p>Copyright &copy; 2024, All Right Reserved @ WashingWale</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 d-none d-lg-block text-center">
                                    <div className="footer-menu">
                                        <ul>

                                            <li><a href="#">Privacy</a></li>
                                            <li><a href="#">Policy</a></li>
                                            <li><a href="#">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </footer>
                </Container>
                <div class="bottom-navbar">
               
                    <button title="Home" class="active">
                        <i class='fa fa-home'></i>
                    </button>
                    <button title="list">
                        <i class='fa fa-list' ></i>
                    </button>
                    <button title="Order">
                        <i class='fa fa-shopping-bag' ></i>
                    </button>
                    <button title="My accounts">
                        <i class='fa fa-user' ></i>
                    </button>
                </div>

            </div>



        </>
    )
}