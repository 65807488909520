import { Container, Row, Col } from 'react-bootstrap'
import './index.scss'
import React from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Range } from "react-range";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Autoplay, FreeMode, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
export default function Shopping() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
    const [values, setValues] = React.useState();
    const [filter, setfilter] = React.useState(true);
    const showFilter = () => {
        setfilter(true)
    }
    return (
        <>
            <div className='car-washing-shopping'>
                <Row>
                    <Col md={3}>
                        <div className='Filter' onClick={showFilter}>
                            <Link to="#" ><i class="fa fa-filter me-1"></i>Filter</Link>
                        </div>
                        {filter &&
                            <div id="sidebar">
                                <div class="product-sidebar">
                                    <div class="product-widget mb-20" style={{ background: "url(img/inner-page/product-wd-bg.png)" }}>
                                        <div class="check-box-item">
                                            <h6 class="product-widget-title mb-20">Make</h6>
                                            <div class="checkbox-container">
                                                <div class="form-inner">
                                                    <input type="text" placeholder="Search Make" />
                                                </div>
                                                <ul>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Mercedes-Benz </span>
                                                            <span class="qty">(1,234)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Toyota </span>
                                                            <span class="qty">(11,353)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Lambhorghini</span>
                                                            <span class="qty">(1,234)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Tesla</span>
                                                            <span class="qty">(4,345)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Mazda</span>
                                                            <span class="qty">(23,990)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Tata</span>
                                                            <span class="qty">(2,345)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Hyundai</span>
                                                            <span class="qty">(5,632)</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-widget mb-20" style={{ background: "url(img/inner-page/product-wd-bg.png)" }}>
                                        <div class="check-box-item">
                                            <h6 class="product-widget-title mb-20">Location</h6>
                                            <div class="checkbox-container">
                                                <div class="form-inner">
                                                    <input type="text" placeholder="Type Location" />
                                                </div>
                                                <ul>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Sydne City</span>
                                                            <span class="qty">(1,234)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">New Delhi</span>
                                                            <span class="qty">(11,353)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Panama City</span>
                                                            <span class="qty">(1,234)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Melbourn City</span>
                                                            <span class="qty">(4,345)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">New York</span>
                                                            <span class="qty">(23,990)</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-widget mb-20" style={{ background: "url(img/inner-page/product-wd-bg.png)" }}>
                                        <div class="check-box-item">
                                            <h6 class="product-widget-title mb-20">Body Type</h6>
                                            <div class="checkbox-container">
                                                <ul>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Wagon</span>
                                                            <span class="qty">(1,234)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Hatchback</span>
                                                            <span class="qty">(11,353)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Sedan</span>
                                                            <span class="qty">(1,234)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Cab Chassis</span>
                                                            <span class="qty">(4,345)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Utility </span>
                                                            <span class="qty">(23,990)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Tata</span>
                                                            <span class="qty">(2,345)</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label class="containerss">
                                                            <input type="checkbox" />
                                                            <span class="checkmark"></span>
                                                            <span class="text">Hyundai</span>
                                                            <span class="qty">(5,632)</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                    </Col>
                    <Col md={9}>
                        <div className="mb-0">
                            <div className="">
                                <div className="bg-white  item2-gl-nav d-lg-flex">
                                    <h6 className="mb-0 mt-3 text-left">Showing 1 to 10 of 30 entries</h6>
                                    <ul className="nav item2-gl-menu ms-auto mt-1" role="tablist">

                                    </ul>
                                    <div className="d-sm-flex item2-gl-group">
                                        <label className="me-2 mt-2 mb-sm-1">Sort By:</label>
                                        <div className="selectgroup">
                                            <label className="selectgroup-item mb-md-0">
                                                <input type="radio" name="value" value="Price" className="selectgroup-input" checked="" /> <span className="selectgroup-button">Price <i className="fa fa-sort ms-1"></i></span>
                                            </label>
                                            <label className="selectgroup-item mb-md-0"> <input type="radio" name="value" value="Popularity" className="selectgroup-input" /> <span className="selectgroup-button flex-wrap">Popularity</span> </label>
                                            <label className="selectgroup-item mb-md-0"> <input type="radio" name="value" value="Latest" className="selectgroup-input" /> <span className="selectgroup-button">Latest</span> </label>
                                            <label className="selectgroup-item mb-0"> <input type="radio" name="value" value="Rating" className="selectgroup-input" /> <span className="selectgroup-button">Rating</span> </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                        </Row> <Row>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                        </Row> <Row>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="product">
                                    <div className="card overflow-hidden">
                                        <div className="d-md-flex">
                                            <div className="item-card9-img">
                                                <Swiper
                                                    spaceBetween={20}
                                                    slidesPerView={1}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                    }}
                                                    navigation={true}
                                                    modules={[Navigation, Autoplay, Pagination]}
                                                    breakpoints={{
                                                        320: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 10,
                                                        },
                                                        640: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        768: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                        1024: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 20,
                                                        },
                                                    }}

                                                    className="mySwiper">
                                                    <SwiperSlide>
                                                        <div className="washingwale-listing">
                                                            <div className="listing-img">
                                                                <a href="listing-details.html">
                                                                    <img src="/assets/images/carwashing.jpg" className="img-fluid" alt="Toyota" />
                                                                </a>

                                                            </div>

                                                        </div>
                                                    </SwiperSlide>
                                                </Swiper>
                                            </div>
                                            <div className="card border-0 mb-0">
                                                <div className="card-body mb-0 pt-0 pl-0">
                                                    <div className="item-card9">
                                                        <a href="cars.html" className="text-dark"><h5 className="font-weight-semibold mt-1">Dignissimos</h5></a>
                                                        <div className="item-card9-desc mb-2">
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-map-marker text-muted me-1"></i> Noida</span>
                                                            </a>
                                                            <a href="/washing-wale-details" className="me-4 d-inline-block">
                                                                <span className=""><i className="fa fa-car text-muted me-1"></i> Driver available</span>
                                                            </a>
                                                        </div>
                                                        <p className="mb-0 leading-tight">Lorem Ipsum available, but the majority have </p>
                                                    </div>
                                                </div>
                                                <div className="card-footer ">
                                                    <div className="item-card9-footer d-sm-flex">
                                                        <div className="item-card9-cost"><h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4></div>
                                                        <div className="ms-auto">
                                                            <a href="/washing-wale-details" className="me-2 d-inline-block" title="Car type"> Book Now</a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </div>
        </>
    )
}