import { Row, Col, Button } from "react-bootstrap"
// import Bannerimg from "./"
import "./index.scss"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import './index.scss';
export const SarchBox = () => {
    return (
        <section className="washingwale">
            {/* style={{ backgroundImage: 'url(/assets/images/banner.jpg)', position: 'relative' }} */}
            <Row>
                <Col md={5}>
                    <p className="explore-text"> <span><i className="fa-solid fa-thumbs-up me-2"></i></span>100% Trusted
                        car washing platform in the india</p>
                    <h1>Find Your Best <br />
                        <span>Car washing center</span>
                    </h1>

                    <div className="form-group location range_form mb-3">
                        <input type="text" className="wasingwale-search" autocomplete="off" placeholder="Choose your city" name="wasingwala_location" id="wasingwala_search" />
                        <Button type="submit" className="btn btn-primary radius_btn w-20">Search</Button>



                    </div>
                </Col>
                <Col md={7}>
                    <div className="banner-imgs">
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: true,
                            }}
                            modules={[Autoplay]}


                            className="mySwiper">
                            <SwiperSlide>
                                <img src="/assets/images/washing-wale-slider.png" className="img-fluid aos" alt="washingwale bannerimage" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/assets/images/washingwala_car_washing.png" className="img-fluid aos" alt="washingwale bannerimage" />
                            </SwiperSlide>


                        </Swiper>

                    </div>
                </Col>
            </Row>

        </section>
    )
}