
import { Routes, Route, BrowserRouter } from 'react-router-dom'

// import Login from "../Components/login"
import { Home } from "../page/home"
import Productdetails from '../page/productdetails'
import Shopping from '../page/shopping'
import React from 'react';
import Seller from '../page/seller';

export const Router = () => {
    window.scrollTo(0, 0);
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path="/" element={<Login />} /> */}
                <Route path="/" element={<Home />} />
                <Route path="/washing-wale-details" element={<Productdetails/>} />
                <Route path="/washing-wale-shopping" element={<Shopping/>} />
                <Route path="/washing-wale-seller" element={<Seller/>} />
            </Routes>
          
        </BrowserRouter>

    )
}