
import React from 'react';
import './App.css';
import './index.css';
import { Footer } from './page/footer';
import { Header } from './page/header';
import { Router } from './routing';
import { Container } from 'react-bootstrap';

// import Chat from './Components/chat'
// import {Reply} from './dictionary'
function App() {



  return (
    <>

      <Header />
      <Container fluid>
        <Router />
      </Container>
      <Footer />
      {/* <Chat msg={Reply}/> */}
    </>

  );
}

export default App;