import "./index.scss"
export default function Discount() {
    return (
        <div class="car-washing-discount" style={{backgroundImage:'url("img/inner-page/product-wd-bg.png")'}}>
      
                <div class="row">
                    <div class="col-lg-12 d-flex align-items-center justify-content-md-between justify-content-center flex-md-nowrap flex-wrap gap-md-3 gap-4">
                        <div class="content wow fadeInUp" data-wow-delay="200ms" style={{visibility: "visible", animationDelay: "200ms"}}>
                            <div class="logo">
                                <img src="assets/img/home3/icon/inner-banner-logo.svg" alt="" />
                            </div>
                            <div class="price">
                                <h4>$74, 345.00</h4>
                            </div>
                            <h5><a href="car-deatils.html">BMW 3 Series Class-2022</a></h5>
                            <a class="primary-btn3" href="car-deatils.html">Explore More <i class="bi bi-arrow-right"></i></a>
                        </div>
                        <div class="product-img wow zoomIn" data-wow-delay="300ms" style={{visibility: "visible", animationDelay: "300ms"}}>
                            <img src="assets/images/home3-inner-banner-img.png" alt="" />
                        </div>
                        <div class="offer-tag wow fadeInUp" data-wow-delay="200ms" style={{visibility: "visible", animationDelay: "200ms"}}>
                            <img src="assets/images/Discount.svg" alt="" />
                            <div class="offer-content">
                                <p>Up To <br /><strong>Discount</strong></p>
                                <span><i class="bi bi-star-fill"></i>40%<i class="bi bi-star-fill"></i></span>
                                <p>Sale</p>
                            </div>
                        </div>
                    </div>
                </div>
            
        </div>
    )
}